export const navLinksdata = [
  {
    _id: 1001,
    title: "Home",
    link: "home",
  },
  
  {
    _id: 1002,
    title: "Projects",
    link: "projects",
  },
  {
    _id: 1009,
    title: "Skills",
    link: "Skills",
  },
  {
    _id: 1013,
    title: "Education",
    link: "Education",
  },
  
  {
    _id: 1003,
    title: "Achievements",
    link: "Achievements",
  },
  {
    _id: 1004,
    title: "Resume",
    link: "Resume",
  },
 
  {
    _id: 1005,
    title: "Contact",
    link: "contact",
  },
 
];